._1yj1lr80 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  overflow-y: auto;
  max-height: 90vh;
  background-color: lightgray;
  border: gray 1px/0 solid;
  padding: 8px;
  margin: 8px -8px;
}