._1yj1lr80 {
  border: gray 1px / 0 solid;
  background-color: #d3d3d3;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  max-height: 90vh;
  margin: 8px -8px;
  padding: 8px;
  display: flex;
  overflow-y: auto;
}

.r7yxk0 {
  aspect-ratio: 1 / 1.41;
  width: 100%;
  max-height: 1200px;
}

/*# sourceMappingURL=index.7d1e9878.css.map */
